/* ========================================================================== */
/* BASE STYLES  fdffddddf */
/* ========================================================================== */
//@import "bootstrap/bootstrap.less";
@import "variables.less";
@import "normalize.less";
@import "lesshat.less";
@import "helpers.less";
@import "../../../node_modules/slick-carousel/slick/slick.less";
@import (inline) "lightbox.css";
@import (inline) "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";




/* ========================================================================== */
main{
  min-height:70vh;
  box-shadow: inset 0px 0px 20px 8px rgba(226, 233, 245, 1);
  background-color: #fafcff;
}
.header{
  .container;
  padding-top:30px;
  padding-bottom:25px;
  .flex(row,nowrap,space-between,center);
  &-tel{
    &_top{
      span{
        font-size:25px;
        font-weight: 700;
        margin-right:30px;
        &:last-child{
          margin-right:0;
        }
      }
    }
    &_call{
      &>span{
        color:@color-link;
        border-bottom:1px dashed #b6cee5;
        cursor:pointer;
        &:hover{
          border-bottom-color:transparent;
        }
      }

    }
  }
  &-lang{
    .lang{
      .flex(row,nowrap,flex-end,center);
      a{
        border:2px solid #e2e9f5;
        color:@color-base;
        background-color: #fff;
        padding:7px 13px;
        &:first-child{
          border-right:0;
        }
        &:last-child{
          border-left:0;
        }
        &.current{
          background-color: @color-link;
          color:white;
        }
        &:not(.current):hover{
          color:@color-link;
        }
      }
    }
  }
  .screen(2px, @s1170,{
    display: none;
  });
  &-mobile{
    .screen(@s1170,900000px,{
      display: none;
    });
    height:60px;
    .flex(row,nowrap,flex-start,center);
    padding:15px;
    &_logo{
      height: 30px;
      margin-left:25px;
      img{
        max-height: 100%;
      }
      a{
        display: inline-block;
        height:100%;
      }
    }
    &_button{
      padding:10px 10px 10px 0;
      svg{
        display: block;
      }
    }
    &_tel{
      margin-left:15px;
      width: 100%;
      text-align: right;
      .screen(2px,500px,{
        display: none;
      });
      .header-tel_top{
        //max-width:170px;
        //text-align: left;
        display:inline-block;
        span{
          font-size:17px;
          white-space:nowrap;
          margin-right:0;
          margin-left:25px;
        }
      }
    }
  }
}
.nav{
  .container;
  &-wrapper{
    border-top:1px solid #e2e9f5;
  }
  &-header{
    .screen(@s1170,900000px,{
      display: none;
    });
  }
  &-menu{
    .reset-around;
    list-style:none;
    .flex(row,nowrap,space-around,center);
    margin-top:15px;
    margin-bottom:18px;
    &>li{
      display: block;
      &.deep{
        position: relative;
        &:hover{
          &>ul{
            display: block;
          }
        }
        &>a{
          &:after{
            content:'';
            .size(8px);
            border-right:2px solid @color-base;
            border-bottom:2px solid @color-base;
            .rotate(45deg);
            display: inline-block;
            margin-left:5px;
            position: relative;
            bottom:3px;
          }
        }
      }
      &>a{
        font-size:17px;
        font-weight: 700;
        color:@color-base;
        &:hover{
          color:@color-link;
          &:after{
            border-color:@color-link;
          }
        }
      }
      &.active{
        &>a{
          color:@color-link;
          &:after{
            border-color:@color-link;
          }
        }
      }
    }
    &_in{
      .reset-around;
      list-style:none;
      position:absolute;
      top:100%;
      left:-25px;
      padding:35px 25px 20px 25px;
      background-color: #fff;
      z-index:2;
      box-shadow: 0px 25px 35px 0px rgba(226, 233, 245, 0.4);
      display: none;
      li{

      }
      a{
        white-space: nowrap;
        color:@color-base;
        margin-bottom:10px;
        &:hover{
          color:@color-link;
        }
      }
    }
  }
  &-wrapper{
    .screen(2px,@s1170,{
      display: none;
      position: fixed;
      width:100vw;
      height: 100vh;
      overflow: auto;
      top:0;
      left:0;
      z-index: 10;
      background-color: #fafcff;
      box-shadow: inset 0px 0px 20px 8px rgba(226, 233, 245, 0.4);
      .translateX(-100%);
      .nav-menu{
        display: block;
        padding-top:15px;
        &>li{
          &>a{
            margin-bottom:25px;
            display: block;
          }
          &.deep{
            &>a{
              position: relative;
              padding-right:15px;
              &:after{
                position: absolute;
                top:6px;
                right:3px;
                .rotate(-45deg);
                transition:transform 0.3s;
              }
            }
            &:hover{
              &>ul{
                display: none;
              }
            }
            &.opened{
              &>a:after{
                .rotate(45deg);
              }
            }
            ul{
              display: none;
              position: static;
              width:100%;
              padding:0;
              background-color: transparent;
              box-shadow: none;
              margin-bottom: 25px;
              padding-left:15px;
              a{
                margin-bottom:15px;
              }
            }
          }
        }
      }
      .nav-header{
        .flex(row,nowrap,space-between,center);
        background-color: #fff;
        padding-top:10px;
        padding-bottom:10px;
        box-shadow: 0px 9px 11px 3px rgba(226, 233, 245, 0.4);
        width:~'calc(100% + 30px)';
        margin-left:-15px;
        margin-right:-15px;
        padding-left:15px;
        padding-right:15px;
      }
      .nav-header_close{
        .flex(row,nowrap,flex-start,center);
        color:@color-link;
        svg{
          fill:@color-link;
          margin-right:10px;
        }
      }
    });
  }
}
.footer{
  &-up{
    &_wrapper{
      border-bottom:1px solid #e2e9f5;
      padding-bottom:15px;
      .screen(2px,1000px,{display: none;});
    }
    .container;
    .flex(row,nowrap,space-between,center);
    margin-top:18px;
  }
  &-menu{
    width:60%;
    ul{
      .reset-around;
      list-style: none;
      .flex(row,nowrap,space-between,center);
      a{
        color:@color-base;
        font-size:17px;
        font-weight:700;
        &:hover{
          color:@color-link;
        }
      }
    }
  }
  &-down{
    .container;
    padding-top:30px;
    padding-bottom:30px;
    .flex(row,nowrap,space-between,center);
    .tel{
      font-size:25px;
      color:@color-link;
      font-weight:700;
      span{
        margin-right:30px;
        &:last-child{
          margin-right:0;
        }
      }
    }
    .copyright a{
      .flex(row,nowrap,flex-start,center);

      span{
        font-size:13px;
        font-style: italic;
        margin-right:5px;
        color:@color-base;
      }
      img{
        position: relative;
        bottom:4px;
      }
    }
    .button{
      button{
        color:#9aa8c0;
        border:2px solid #e2e9f5;
        background-color: #fff;
        outline: none;
        padding:10px 50px;
      }
    }
    .screen(2px,1000px,{
      .flex(column,nowrap,flex-start,center);
      .tel{
        color:@color-base;
        margin-bottom:25px;
        .screen(2px,570px,{
          span{
            display: block;
            margin:0;
            margin-bottom:10px;
            font-size:28px;
          }
        });
      }
      .button{
        margin-bottom: 25px;
      }
    });
  }
}
.breadcrumbs{
  padding:25px 0;
  a{
    color:#96a4bb;
    font-size:14px;
  }
  span{
    color:@color-base;
    font-size:14px;
  }
  .delimiter{
    display: inline-block;
    color:#d1dbeb;
    font-size:14px;
    margin:0 10px;
    user-select:none;
  }
}

/* ========================================================================== */
.pagination{
  ul{
    list-style:none;
    .reset-around;
    .flex(row,nowrap,center,center);
    padding-bottom:50px;
  }
  li{
    .size(45px);
    border:2px solid #d1dbeb;
    border-radius:50%;
    overflow:hidden;
    background-color: #fff;
    margin:0 5px;
    &>span,
    &>a{
      .flex(row,nowrap,center,center);
      .size(100%);
      color:black;
      cursor: pointer;
      &.next,
      &.prev{
        &:hover{
          svg{
            fill:@color-link;
          }
        }
      }
      &:hover{
        color:white;
        svg path{
          fill:white!important;
        }
      }
    }
    &:hover{
      border-color:@color-link;
      background-color: @color-link;
    }
    &.disabled{
      display: none;
      & .next,
      & .prev{
        cursor: default;
        &:hover{
          svg{
            fill:#797979!important;
          }
        }
      }
    }
    &.active{
      background-color: @color-link;
      border-color:@color-link;
      span{
        background-color: @color-link;
        color:white;
        cursor: default;

      }
    }

  }
  svg{
    display: block;
    fill:#797979;
  }
}
.h1-title{
  h1{
    font-size:30px;
    font-weight:700;
    margin-bottom:35px;
    line-height: 1.1;
  }
  &.date{
    h1{
      span{
        font-size:15px;
        color:#999999;
        font-weight:400;
        margin-left:10px;
      }
    }
  }
  .screen(2px,@s750,{
    h1{
      font-size:25px;
    }
    &.date{
      h1 span{
        display: block;
        margin-left:0;
        margin-top:15px;
      }
    }
  });
}
.calc{
  &-wrapper{
    .flex(row,wrap,flex-start,flex-start);
    padding-top:60px;
  }
  &-slider{
    width:45%;
    margin-right:5%;
    .screen(2px,1000px,{
      width:100%;
      margin-right:0;
    });
    &_block{
      margin-bottom:80px;
      .title{
        font-size:17px;
        font-weight: 700;
        margin-bottom:15px;
      }
      .input-in{
        position: relative;
        top:17px;
        .screen(2px,768px,{margin-bottom: 15px;});
        &_text{
          position: absolute;
          top:50%;
          .translateY(-50%);
          right:1em;
          font-size:18px;
        }
        &:before{
          content:'';
          position: absolute;
          bottom:-1px;
          left:0;
          width:15px;
          height:3px;
          background-color: @color-red;
          .screen(2px,768px,{display: none;});
        }
        input{
          border:2px solid #e2e9f5;
          width:100%;
          height: 50px;
          padding:0 15px;
          font-size:28px;
          outline:none;
        }
      }
      .underslide{
        color:#999999;
        .screen(768px,900000px,{display: none});
      }
      .vue-slider{
        border-radius:0;
        background-color: transparent!important;
        &-dot{
          border:1px solid rgb(226, 233, 245);
          box-shadow: 0px 3px 10px 0px rgba(226, 233, 245, 0.4)!important;
          position: relative;
          &:after{
            content:'';
            border-radius: 50%;
            background-color: rgb(204, 0, 0);
            box-shadow: inset 0px 2px 0px 0px rgba(153, 0, 0, 0.4);
            position: absolute;
            top:50%;
            left:50%;
            .translate(-50%,-50%);
            .size(14px)
          }

        }
        &-piecewise-label{
          font-size:15px!important;
          color:#999999!important;
          top:22px!important;
          cursor:pointer;
        }
        &-process{
          border-radius:0!important;
          background-color: @color-red!important;
        }
        &-wrap{
          .screen(2px,768px,{display: none});
          padding:0 15px!important;
        }
        &-piecewise li{
          display: none;
          &:last-child{
            display: block;
          }
          &:first-child{
            display: block;
          }
        }
        .screen(2px,768px,{
          .vue-slider{
            &-piecewise{
              display: none;
            }
          }
        });
      }
    }
  }
  &-plate{
    width:50%;
    padding-left:25px;
    .screen(2px,1000px,{
      width:100%;
      padding-left:0;
    });

    &_button{
      button{
        color:#9aa8c0;
        border:2px solid #e2e9f5;
        background-color: #fff;
        outline: none;
        padding:10px 10px;
        width:100%;
        height: 50px;
      }
      .link{
        margin-bottom:15px;
        a{
          font-size:16px;
          color:#fff;
          font-weight:700;
          text-align:center;
          padding:19px 10px;
          display: block;
          background-color: @color-link;
          &:after{
            //.size(25px);
            display: inline-block;
            //background-image: url(../img/arrow-right.svg);
            //background-repeat:no-repeat;
            //background-position: center center;
            content:'→';
            margin-left:15px;
          }
        }
      }
    }
    &_in{
      background-color: rgb(255, 255, 255);
      box-shadow: 0px 10px 40px 0px rgba(208, 218, 234, 0.4);
      padding:50px;
      .screen(2px,670px,{
        width:~'calc(100% + 30px)';
        margin-left:-15px;
        margin-right:-15px;
        padding:25px 15px;
      });
    }
    &_wrapper{
      .flex(row,wrap,flex-start,flex-start);
    }
    &_block{
      width:40%;
      margin-bottom:35px;
      &:nth-of-type(2n){
        width:60%;
      }
      .screen(2px,670px,{
        width:100%;
        &:nth-of-type(2n){
          width:100%;
        }
      });
      .title{
        margin-bottom:15px;
      }
      .text{
        &-in{
          font-size:28px;
          font-weight: 700;
          margin-right:5px;
          &.delim{
            &:after{
              content:'/';
              color:#d1dbeb;
              font-size:18px;
              font-weight:700;
              margin-left:5px;
            }
          }
          .delim-in{
            font-size:18px;
          }
        }
        &-out{
          font-size:18px;
          font-weight: 700;
        }
      }
    }
    &_text{
      font-size:12px;
      color:#999999;
      text-align:center;
      line-height:20px;
      width:80%;
      margin:30px auto 0;
    }


  }
  &-got{
    &_check{
      .flex(row,nowrap,flex-start,center);
      .check{
        .size(30px);
        border:2px solid #d1dbeb;
        .flex(row,nowrap,center,center);
        margin-right:15px;
        cursor: pointer;
        &.active{
          border-color:@color-red;
        }
        svg{
          display: block;
        }
      }
      .title{
        font-size:15px;
        cursor: pointer;
        user-select:none;
      }
      &.personal_accord{
        margin-top:-30px;
        a{
          color:@color-link;
        }
        .screen(2px,768px,{
          margin-top:-10px;
          display: block;
          .check{
            margin-bottom: 15px;
          }
          a{
            display: inline;
          }
        });
      }

    }
    &_text{
      color:#999999;
      font-size:13px;
      margin-left:45px;
      margin-top:15px;
      margin-bottom:50px;
      p{
        margin:0 0 10px 0 ;
      }
    }
  }
  &-tab{
    padding-top:50px;
    &_wrapper{
      .flex(row,nowrap,space-between,center);
      button{
        font-size:18px;
        font-weight:700;
        color:#aebace;
        border:2px solid #d1dbeb;
        outline: none;
        background: #fff;
        padding:22px 10px;
        width:32%;
        position: relative;
        &:not(.active):hover{
          border-color:#a3abb8;
          color:#a3abb8;
        }
        &.active{
          background: linear-gradient(to top,rgb(209,219,235) 0%, rgb(224,232,245) 100%, rgb(209,219,235) 100%);
          padding:24px 20px;
          border:0;
          color:@color-base;
          &:after{
            content:'';
            position: absolute;
            top:100%;
            border:11px solid transparent;
            border-top-color:rgb(209,219,235);
            left:50%;
            .translateX(-50%);
          }
        }
      }
      .screen(2px,900px,{
        .flex(column,nowrap,flex-start,center);
        button{
          width:100%;
          max-width:290px;
          margin-bottom:15px;
          &.active:after{
            display: none;
          }
        }
      });
    }
  }
}
.pref{
  .flex(row,wrap,flex-start,flex-start);
  margin-top:60px;
  padding-bottom:50px;
  &-about{
    width:50%;
    font-size:15px;
    line-height: 25px;
    padding-right:25px;
    p{
      margin:0 0 25px 0;
    }
  }
  &-plus{
    width:50%;
    padding-left:80px;
    dt{
      .reset-around;
      font-size:17px;
      margin-bottom:10px;
      font-weight:700;
      position: relative;
      word-wrap: break-word;
      img{
        width:50px;
        position: absolute;
        top:0;
        left:-80px;
      }
    }
    dd{
      .reset-around;
      font-size:15px;
      line-height:25px;
      margin-bottom:35px;
    }
  }
  .title{
    font-size:30px;
    font-weight:700;
    margin-bottom:25px;
  }
  .screen(2px,900px,{
    &-about{
      width:100%;
      padding:0;
    }
    &-plus{
      width:100%;
    }
    .title{
      font-size:25px;
      line-height: 1.1;
    }
  });
}
.b{
  background-color: #fff;
  position: fixed;
  top:~'calc(50% - 82px)';
  right:0;
  transform: perspective(1px) translateZ(0) translateX(100%) translateX(-85px) translateY(0);
  //transform: perspective(1px) translateZ(0) translate(~'calc(100% - 85px)',-50%);

  padding:20px;
  .border-radius(5px,0,0,5px);
  box-shadow: 0px 10px 40px 0px rgba(208, 218, 234, 0.4);
  transition:all 0.5s;
  will-change: transform;
  z-index:5;
  font-smoothing: antialiased !important;
  backface-visibility: hidden;
  filter: blur(.0px);
  & svg,
  & a{
    display: block;
    color:#85a4d5;
  }
  svg{
    margin-right:20px;
  }
  &-calc{
    margin-bottom:30px;
    cursor: pointer;
    color:#85a4d5;
    .flex(row,nowrap,flex-start,center);
  }
  &-order{
    a{
      .flex(row,nowrap,flex-start,center);
    }
  }

  &:hover{
    transform:perspective(1px) translateZ(0) translate(0px,0);
    //transform:perspective(1px) translateZ(0) translate(0px,-50%);
    font-smoothing: antialiased !important;
    filter: blur(.0px);
  }
}
.anketa{
  &-tab{
    padding-top:0;
  }
  &-progress{
    ul{
      list-style:none;
      .reset-around;
      .flex(row,nowrap,space-between,center);
      margin-top:35px;
      user-select:none;
    }
    li{
      color:@color-link;
      padding:10px 0;
      border-bottom:2px solid transparent;
      cursor: pointer;
      &.active{
        border-bottom:2px solid @color-red;
        z-index:2;
        color:@color-base;
      }
      &.completed{
        color:@color-base;
      }
      &.anketa-progress_arrow{
        color:#d1dbeb;
        cursor: default;
      }
    }
  }
  &-button{
    text-align: right;
    margin-top:35px;
    padding-bottom:50px;
    button{
      border:0;
      background-color: @color-link;
      color:white;
      font-size:16px;
      font-weight:700;
      padding:19px 85px;
      .screen(2px,768px,{
        width:100%;
        padding:19px;
      });
    }
  }
  hr{
    border:0;
    width:100%;
    height: 0;
    border-top:2px solid #e2e9f5;
    position: relative;
    bottom:2px;
    z-index: 1;
    .reset-around;
  }
  &-step {
    &_block {
      padding:25px 0;
      .atitle{
        font-size:17px;
        font-weight:700;
        margin-bottom:20px;
        span{
          font-size:15px;
          font-weight:400;
        }
      }
      span.error{
        color:@color-red;
      }
      input{
        background-color: #fafcff;
      }
      .tb1{
        &-scope{
          &_wrapper{
            .flex(row,wrap,flex-start,flex-start);
            .screen(2px,@s1170,{
              //display: block;
            });
          }
          &_block{
            width:~'calc(100% / 3)';
            margin-bottom:25px;
            padding-right:15px;
            .screen(768px,@s1170,{
              width:50%;
            });
            .screen(2px,768px,{
              width:100%;
            });
            input{
              display: none;
              &:checked{
                & + label{
                  color:@color-base;
                  .dot{
                    &:after{
                      background-color: @color-red;
                      box-shadow: inset 0px 2px 0px 0px rgba(153, 0, 0, 0.4);
                    }
                  }
                }
              }
            }
            label{
              font-size:15px;
              color:#666666;
              position: relative;
              padding-left:50px;
              display: inline-block;
              min-height: 30px;
              padding-top:5px;
              cursor: pointer;
              .dot{
                .size(30px);
                position: absolute;
                top:0;
                left:0;
                border:1px solid #e2e9f5;
                border-radius:50%;
                background-color: #fff;
                box-shadow: 0px 3px 10px 0px rgba(226, 233, 245, 0.4);
                &:after{
                  content:'';
                  .size(14px);
                  background-color: #e2e9f5;
                  border-radius:50%;
                  position: absolute;
                  top:50%;
                  left:50%;
                  .translate(-50%,-50%);
                }
              }
            }
          }
        }
      }
      .tb{
        &-object{
          margin:15px 0 35px;
          max-width:320px;
        }
      }
      .input{
        &-scope{
          &_wrapper{
            .flex(row,wrap,flex-start,flex-start);
            margin:15px 0 35px;
            .screen(2px,768px,{
              display: block;
            });
          }
          &_block{
            width:~'calc(100% / 3)';
            padding-right:25px;
            margin-bottom:35px;
            .screen(2px,768px,{
              width:100%;
            });
            .fileInput{
              width:100%;
              height:50px;
              background-color: #e8eef7;
              border:2px solid #e8eef7;
              cursor:pointer;
              padding-left:20px;
              .flex(row,nowrap,flex-start,center);
              svg{
                margin-right:10px;
              }
              &.error{
                border-color:@color-red;
                span{
                  color:@color-base;
                }
              }
            }
            label{
              font-size:17px;
              font-weight:700;
              display: block;
              margin-bottom: 15px;
            }
            &_half{
              width:50%;
              padding-right:25px;
              margin-bottom:35px;
              .screen(2px,768px,{
                width:100%;
              });
              label{
                font-size:17px;
                font-weight:700;
                display: block;
                margin-bottom: 15px;
                &.link{
                  position: relative;
                  a{
                    position: absolute;
                    top:0;
                    right:0;
                    font-size:15px;
                    font-weight:400;
                    color:@color-link;
                    &:before{
                      content:'';
                      .size(18px);
                      display: inline-block;
                      background-image: url(../img/pdf.svg);
                      background-repeat:no-repeat;
                      vertical-align: bottom;
                      margin-right:5px;
                    }
                    &:hover{
                      span{
                        border-color:transparent;
                      }
                    }
                    span{
                      border-bottom:1px dashed ;
                    }
                    .screen(2px,768px,{
                      position: static;
                    });
                  }
                }
              }
              .fileInput{
                width:100%;
                height:50px;
                background-color: #e8eef7;
                border:2px solid #e8eef7;
                cursor:pointer;
                padding-left:20px;
                .flex(row,nowrap,flex-start,center);
                svg{
                  margin-right:10px;
                }
                &.error{
                  border-color:@color-red;
                  span{
                    color:@color-base;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &-slider{
    margin-top:15px;
    &_wrapper{
      .flex(row,nowrap,space-between,flex-start);
      .screen(2px,768px,{
        display: block;
        .calc-slider_block{
          margin-bottom: 45px;
        }
      });
    }
    &_val{
      width:48%;
      .screen(2px,768px,{
        width:100%;
      });
    }
    &_time{
      width:48%;
      .screen(2px,768px,{
        width:100%;
      });
    }
    &_under{
      border:2px solid #e7ecf6;
      border-top:0;
      text-align:center;
      height: 30px;
      &>span{
        display: inline-block;
        border:2px solid #e7ecf6;
        border-radius:5px;
        background-color: #fafcff;
        padding:17px 62px;
        position: relative;
        top:0px;
        & b{
          font-weight:700;
          font-size:28px;
          font-style: normal;
        }
        .slash{
          color:#d1dbeb;
          font-size:18px;
        }
        & .tm,
        & i{
          font-size:18px;
          font-weight:700;
          font-style:normal;
        }
      }
      .screen(2px,768px,{
        height: auto;
        border:0;
        &>span{
          width:100%;
          margin-bottom: 15px;
        }
      });
    }
  }
}
span.error,
p.error,
div.error{
  color:@color-red;
}
.multiselect{
  cursor: pointer;
  &.error{
    & .multiselect__tags{
      border-color:@color-red;
    }
  }
  &__tags{
    border:2px solid #e2e9f5;
    background-color: #fafcff;
    border-radius:0;
    height:50px;
    padding:12px 40px 0 8px;
  }
  &__single{
    background-color: #fafcff;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__select{
    height: 50px;
  }
  &__content{
    background-color: #fafcff;
    border-radius:0;
    border:2px solid #e2e9f5;
    border-top:2px solid #e2e9f5!important;
    border-bottom:2px solid #e2e9f5!important;

  }

}
.contacts{

  &-wrapper{
    .flex(row,nowrap,flex-start,flex-start);
    .screen(2px,@s1170,{
      display:block;
    });
  }
  &-block{
    width:~'calc(100% / 3)';
    padding-left:60px;
    position: relative;
    &_img{
      position: absolute;
      top:0;
      left:0;
    }
    .title{
      font-size:17px;
      font-weight:700;
      line-height: 1;
      margin-bottom:5px;
    }
    a{
      color:@color-base;
    }
    .screen(2px,@s1170,{
      width:100%;
      margin-bottom:15px;
    });
  }
  &-maps{
    height:580px;
    width:~'calc(100% - 200px)';
    .screen(2px,@s1170,{
      width:100%;
    });
  }
  &-map{
    padding-bottom: 50px;
    margin-top:50px;
    position: relative;
  }
  &-form{
    background-color: white;
    box-shadow: 0px 10px 40px 0px rgba(208, 218, 234, 0.4);
    position: absolute;
    right:0;
    top: 35px;
    width: 360px;
    min-height: 510px;
    z-index: 3;
    padding:25px;
    .title{
      font-size:17px;
      font-weight:700;
      text-align:center;
      margin-bottom:25px;
    }
    label{
      display: block;
      margin-bottom:5px;
    }
    input{
      height:42px;
      padding:0 15px
    }
    .input-wr{
      margin-bottom:15px;
    }
    textarea{
      width:100%;
      max-width:100%;
      min-width:100%;
      height: 115px;
      min-height: 115px;
      border:2px solid #e2e9f5;
      outline: none;
      padding:15px;
    }
    button{
      width:100%;
      background-color: @color-link;
      font-size:16px;
      color:white;
      font-weight:700;
      border:0;
      padding:19px 10px;
    }
    .screen(2px,@s1170,{
      max-width:360px;
      width:100%;
      margin:0 auto 25px;
      position: static;
    });
  }
}
.question{
  padding-bottom: 50px;
  &-block{
    margin-bottom: 10px;
    .text{
      display: none;
      margin-left:58px;
      .editor{
        margin:10px 0 0;
      }
    }
    .title{
      .flex(row,nowrap,flex-start,flex-start);
      cursor: pointer;
      span{
        display: inline-block;
        padding-top:12px;
        font-size:17px;
        font-weight:700;
        color:@color-link;
        transition:all 0.3s;
        width:~'calc(100% - 60px)';
      }
      svg{
        margin-right:15px;
        transition:all 0.3s;
        path{
          transition:all 0.3s;
        }
      }
    }
    &.opened{
      .title{
        span{
          color:@color-base;
        }
        svg{
          .rotate(45deg);
          fill:@color-base!important;
          path:not(:first-child){
            fill:@color-base!important;
          }
          path:first-child{
            stroke:@color-base!important;
          }
        }
      }
    }
  }
}
.product{
  .h1-title{
    margin-top:35px;
  }
  hr{
    .reset-around;
    border:0;
    border-top:3px solid #e2e9f5;
    position: relative;
    bottom:3px;
  }
  .anketa{
    &-progress{
      ul{
        .flex(row,nowrap,flex-start,flex-start);
        li{
          margin-right:50px;
          border-bottom-width: 3px;
          span{
            font-size:20px;
            .screen(2px,768px,{
              font-size:16px;
            });
          }
          &.active{
            color:@color-base;
          }
        }
      }
    }
  }
  &-section{
    margin-top:35px;
    padding-bottom:50px;
  }
  &-get{
    &_wrapper{
      .flex(row,nowrap,space-between,flex-start);
      padding-bottom:50px;
      .screen(2px,@s1170,{
        display: block;
      });
    }
  }
  &-left{
    width:32%;
    .screen(2px,@s1170,{
      width:100%;
      max-width:320px;
      margin:0 auto 35px;
      text-align:center;
    });
  }
  &-right{
    width:65%;
    h1{
      line-height: 25px;
    }
    .screen(2px,@s1170,{
      width:100%;
    });
  }
  &-tab{
    padding-top:0;
    .calc-tab{
      &_wrapper{
        display: block;
        button{
          width:100%;
          font-size:16px;
          text-align: left;
          padding:23px 15px 23px 25px;
          margin-bottom:20px;
          border:2px solid #dae3f1;
          &.active{
            background: #dae3f1;
            &:after{
              top: 21px;
              left: auto;
              right: -40px;
              border-top-color: transparent;
              border-left-color: #dae3f1;
              border-width: 13px;
            }
          }
        }
      }
    }
  }
  &-cat{
    margin:20px 0;
    &_title{
      .flex(row,nowrap,flex-start,flex-start);
      margin-bottom:10px;
      b{
        font-size:25px ;
        .size(60px);
        border-top:5px solid @color-link;
        border-left:5px solid @color-link;
        .flex(row,nowrap,center,center);
        color:@color-link;
        margin-right:30px;
      }
      span{
        width:~'calc(100% - 90px)';
        padding-top:20px;
        font-size:17px;
        font-weight:700;
        color:@color-link;

      }
    }
    &_text{
      margin-left:90px;
    }
  }
}
.news{
  &-list{
    &_wrapper{
      .flex(row,wrap,flex-start,flex-start);
      width:~'calc(100% + 30px)';
      margin:0 -15px;
      padding-bottom:30px;
      .screen(2px,768px,{
        width:100%;
        margin:0 ;
      });
    }
    &_block{
      width:~'calc(100% / 3 - 30px)';
      margin:0 15px 50px 15px;
      .img{
        height: 200px;
        background-repeat:no-repeat;
        background-position: center center;
        background-size:cover;
        margin-bottom:15px;
        &.no-img{
          background-color: #e2e9f5;
          background-image: url(../img/noimg.png)!important;
          background-size:initial;
        }
        a{
          display: block;
          height: 100%;
        }
      }
      .title{
        font-size:17px;
        font-weight: 700;
        margin-bottom:15px;
        a{
          color:@color-base;
          line-height:25px;
          &:hover{
            color:@color-link;
          }
        }
      }
      .text{
        line-height: 25px;
        margin-bottom: 15px;
      }
      .date{
        color:#999999;
      }
      .screen(768px,@s1170,{
        width:~'calc(100% / 2 - 30px)';
      });
      .screen(2px,768px,{
        width:100%;
      });
    }
  }
  &-content{
    padding-bottom: 25px;
  }
  &-social{
    padding:15px 0 50px 0;
  }
}
#anketa-progress{
  .screen(2px,@s1170,{
    width:~'calc(100% + 30px)';
    overflow: hidden;
    height: 80px;
    position: relative;
    margin-left:-15px;
    margin-right:-15px;
    padding:0 15px;
    &:after{
      position: absolute;
      width:30%;
      height: 43px;
      bottom:0;
      right:-15px;
      content:'';
      background: linear-gradient(to left, white,transparent);
      z-index:3;
    }
    &:before{
      position: absolute;
      width:30%;
      height: 43px;
      bottom:0;
      left:-15px;
      content:'';
      background: linear-gradient(to right, white,transparent);
      z-index:3;
    }
    ul{
      min-width:1140px;
      position: absolute;
      bottom:0;
      left:0;
      .translateX(10px);
      z-index:2;
    }
  });

}
#buttons{
  .screen(2px,@s1170,{
    display: none;
  });

}
