html{
	box-sizing: border-box;
	& body{
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		    flex-direction: column;
		min-height: 100%;
		min-height: 100vh;
		-webkit-tap-highlight-color:transparent;
		& .content {
		  -ms-flex: 1 0 auto;
		      flex: 1 0 auto;
		}
		& .footer {
		  -ms-flex: 0 0 auto;
		      flex: 0 0 auto;
		}
	}
}
*, *:before, *:after {
	box-sizing: inherit;
}
html, body  {
	min-height: 100%;
	width: 100%;
	background-color: @color-bg;
	color:@color-base;
}

body,  select, input, textarea  {
	font-size: @font-size-base;
	line-height: @font-line-height;
	font-weight: @font-weight-default;
	font-family: @font-default;
	-webkit-tap-highlight-color:transparent;
}
.screen(@min, @max, @ruleset) {
  @media (min-width: @min) and (max-width: (@max - 1)) {
    @ruleset();
  }
}

.screen(mobile, @ruleset) {
	@media (min-width: 2px) and (max-width: 1170px) {
		@ruleset();
	}
}

.screen(desktop, @ruleset) {
	@media (min-width: 1170px) and (max-width: 90000px) {
		@ruleset();
	}
}


.flex(@row: row,@wrap: nowrap,@justify: flex-start,@align: stretch) {
	display: flex;
	flex-flow:@row @wrap;
	justify-content: @justify;
	align-items: @align;
}



.container{
	margin-left: auto;
	margin-right: auto;
	.screen(2px, 1170px,{
		width: 100%;
		padding-left: 15px;
		padding-right: 15px;
		min-width: 320px;
		});
	//.screen(@s1300,@s1600, {
	//	width: 1270px;
	//	padding:0 15px;
	//	});
	.screen(1170px,70000px, {
		width: 1170px;
		padding-left: 15px;
		padding-right: 15px;
	});
}

.scr-no{
	overflow: hidden;
}
a{
	text-decoration: none;
	outline: none;
	display: inline-block;
	&:hover{
		text-decoration: none;
	}
	&:focus{
		text-decoration: none;
		outline: none;
	}
}
.empty{
	height:0;
	overflow: hidden;
	.reset-around;
}
.btn-green{
	background-color: @color-green;
	height: 57px;
	outline: none;
	border: 0;
	border-radius: 28px;
	padding: 0 30px;
	font-family: 'Exo 2', sans-serif;
	font-size:15px;
}
.input{
	&-wrapper{
		margin-bottom: 25px;
		span.error{
			color:@color-red;
		}
	}
	&-round{
		background-color: #fff;
		border:2px solid #e2e9f5;
		outline:none;
		border-radius: 0;
		height: 50px;
		padding: 0 30px;
		display: block;
		width:100%;
		&.error{
			border-color:@color-red;
		}
	}
	&-label{
		display: block;
		margin-bottom:10px;
		font-size:16px;
		font-weight:400;
	}
}
.input-round{

}
.reset-around{
	margin:0;
	padding:0;
}
.modal{
	&-mask{
		position: fixed;
		z-index: 9998;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .5);
		display: table;
		transition: opacity .3s ease;
	}
	&-wrapper{
		display: table-cell;
		vertical-align: middle;
	}
	&-container{
		width: 300px;
		margin: 0px auto;
		padding: 0;
		background-color: #fff;
		box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
		transition: all .3s ease;
		position: relative;
		border:1px solid #cdcdcd;
	}
	&-close{
		background-color: white;
		position: absolute;
		top:-40px;
		right:-40px;
		padding:10px;
		cursor: pointer;
		.screen(2px,768px,{
			top:0;
			right:0;
		});
		&:hover{
			svg{
				fill:@color-link;
			}
		}
		svg{
			fill:@color-base;
			display: block;
		}
	}
	&-callback{
		&-button{
			font-family: @font-default;
			font-size:16px;
			color:white;
			background-color: @color-link;
			border:0;
			font-weight:700;
			padding:20px 10px;
			width:100%;
			margin-top:5px;
		}
		.modal{
			&-container{
				max-width:420px;
				width:~'calc(100% -	40px)';
				text-align:left;
				.screen(2px,768px,{
					width:100%;
					height: 100vh;
				});
				.title{
					font-size:30px;
					font-weight:700;
					font-family: @font-default;
					text-align: center;
					margin-bottom:35px;
				}
				.under{
					font-weight:300;
					margin-top:30px;
					margin-bottom:20px;
				}
				.text{
					white-space: normal;
					font-family: @font-default;
					font-size:15px;
					line-height:22px;
					text-align: center;
					margin:0 0 20px 0;
				}
			}
			&-body{
				padding:35px 30px 15px;
				max-height: ~'calc(100vh - 90px)';
				overflow:auto;
				.screen(2px,768px,{
					width:100%;
					height: 100%;
				});
			}
		}
	}
	&-log{
		cursor: default;
		&_make{
			.title{
				font-size:19px;
				font-weight:700;
				text-align: center;
			}
			.text{
				white-space: normal;
				font-family: @font-default;
				font-size:15px;
				line-height:22px;
				text-align: center;
				margin:0 0 20px 0;
			}
		}
		.modal-container{
			max-width:440px;
			width:~'calc(100% - 40px)';
		}
		.modal-body{
			max-height: 90vh;
			overflow: auto;
		}
		&_remember{
			border:0;
			background: none;
			outline:none;
			color:@color-link;
			border-bottom:1px solid @color-link;
			.reset-around;
			line-height:1;
			margin-left:15px;
			.screen(2px,450px,{
				margin-top:25px;
				margin-left:0;
			});
			&:hover{
				border-color:transparent;
			}
		}
		&_social{
			width:~'calc(100% + 70px)';
			margin-left:-35px;
			margin-bottom:-15px;
			border-top:1px solid #e5e5e5;
			.flex(row,wrap,space-between,center);
			margin-top:40px;
			position: relative;
			//top:15px;
			padding:20px 25px 0 35px;
			p{
				color:#777777;
				margin-right:10px;
			}
		}
		&_up{
			.flex(row,nowrap,flex-start,flex-start);
			button{
				border:0;
				background:none;
				font-size:19px;
				font-weight:700;
				background-color: #e8eef0;
				width:65%;
				text-align: center;
				padding: 25px 15px;
				&:nth-of-type(1){
					width:45%;
				}
				&.current{
					background-color: #fff;
				}
			}
		}
		&_cont{
			padding:35px;
		}
	}
	&-youtube{
		.modal{
			&-container{
				.size(640px,390px);
				border-width:0;
				.screen(2px,@s750,{
					.size(100%,100%);
					iframe{
						width:100%;
						height: 100%;
					}
					.modal-body{
						&>div{
							height:100%;
						}
					}
				});
			}
			&-close{
				background-color: #0c0c0c;
				&:hover{
					svg{
						fill:@color-green;
					}
				}
				svg{
					fill:#8a8888;
				}
			}
			&-body{
				height:100%;
			}
		}
	}
	&-thank{
		.modal{
			&-container{
				max-width:420px;
				width:~'calc(100% -	40px)';
				text-align:left;
				.screen(2px,768px,{
					width:100%;
					height: 100vh;
				});
				.title{
					font-size:20px;
					font-weight:700;
					font-family: @font-default;
					text-align: center;
					margin-bottom:15px;
				}
				.under{
					font-weight:300;
					margin-top:30px;
					margin-bottom:20px;
				}
				.text{
					white-space: normal;
					font-family: @font-default;
					font-size:15px;
					line-height:22px;
					text-align: center;
					margin:0 0 20px 0;
				}
			}
			&-body{
				padding:35px 30px 15px;
				max-height: ~'calc(100vh - 90px)';
				overflow:auto;
				.screen(2px,768px,{
					width:100%;
					height: 100%;
				});
			}
		}
	}
	&-calc{
		.modal{
			&-container{
				width:1140px;

			}
			&-body{
				padding:0 50px 50px 50px;
				max-height: 90vh;
				overflow: auto;
			}
		}
	}
}
.modal-enter {
	opacity: 0;
}
.modal-leave-active {
	opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}
label.req{
	&:after{
		content:'*';
		margin-left:5px;
		color:@color-red;
	}
}
.mg10{
	padding-top:10px;
	.tb1-scope_block{
		margin-bottom:0!important;
		label{
			margin-bottom:10px!important;
		}
	}
}
*[v-cloak]{
	display: none;
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active для <2.1.8 */ {
  opacity: 0
}
.section-in {
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
}
.text-center{
	text-align:center;
}
.editor{
	font-size: 15px;
	color:@color-base;
	line-height: 25px;
	& a{
		color:@color-link;
		border-bottom:1px solid @color-link;
		&:hover{
			border-color:transparent;
		}
	}
	& p{
		margin:0 0 0.7em 0;
	}
	& ul{
		.reset-around;
		padding-left:40px;
		margin-top:15px;
		margin-bottom: 15px;
	}

}
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes popIn {
	from {
		.scale(0.5);
	}
	to {
		.scale(1);
	}
}
@keyframes popIn {
	from {
		.scale(0.5);
	}
	to {
		.scale(1);
	}
}




